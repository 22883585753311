import * as React from "react"
import BlogLayout from "../../../components/word/BlogLayout"
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
} from "@chakra-ui/react"
import { ChevronRightIcon } from "@chakra-ui/icons"
import WordTag from "../../../components/word/WordTag"
import { graphql } from "gatsby"

export default function CategoryIndexPage({ data }: any): JSX.Element {
  const { allMdx }: any = data
  const { nodes }: any = allMdx
  let cats: string[] = []
  for (let node of nodes) {
    if (node.frontmatter.categories) {
      for (let cat of node.frontmatter.categories) {
        cats.push(cat)
      }
    }
  }
  cats = Array.from(new Set(cats)).sort()
  return (
    <BlogLayout pageTitle={"categories"}>
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        pt={6}
        pl={4}
        pb={1}
      >
        <BreadcrumbItem>
          <BreadcrumbLink href="/word">word</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="/word/category">category</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Box mt={8} ml={4}>
        <Flex flexDirection={"column"} gap={2} mt={4} ml={2}>
          {cats &&
            cats.map((cat: string, idx: number) => {
              return (
                <Box>
                  <WordTag text={cat} kind={"category"} key={idx} />
                </Box>
              )
            })}
        </Flex>
      </Box>
    </BlogLayout>
  )
}

export const query = graphql`
  {
    allMdx {
      nodes {
        frontmatter {
          categories
        }
      }
    }
  }
`
